import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState, AppThunk} from "../../app/store"
import {fetchCount, fetchFoodProducts} from "./foodOrderingAPI"

import {ProductState, FoodOrderingState, FoodProduct, CartState} from "./types";

export const foodCartInitState: ProductState = {
  qty: 0,
  isAdded: false,
  status: "idle"
}
export const initialState: FoodOrderingState = {
  value: 0,
  status: "idle",
  productsData: {},
  productList: [],
  cart: []

}

export const fetchFood = createAsyncThunk(
  "foodOrdering/fetchFood",
  async () => {
    const response: { data: FoodProduct[] } = await fetchFoodProducts()
    return response.data
  },
)

export const foodOrderingSlice = createSlice({
  name: "foodOrdering",
  initialState,
  reducers: {
    increment: (state, action: PayloadAction<FoodProduct>) => {
      console.log({action})
      const {payload: product} = action
      const existedCartItems = state.cart.filter((x) => x.product.name === product.name);
      if (existedCartItems.length) {
        existedCartItems[0].qty += 1
      } else {
        const cartItem: CartState = {
          product,
          qty: 1
        }
        state.cart = [...state.cart, cartItem]

      }


      console.log("total qty", Object.values(state.productsData).reduce<number>((sum, x ) => sum + x.qty, 0)
      )
    },
    decrement: (state, action: PayloadAction<FoodProduct>) => {
      console.log({action})
      const {payload: product} = action
      const existedCartItems = state.cart.filter((x) => x.product.name === product.name);
      if (existedCartItems.length &&  existedCartItems[0].qty > 0) {
        existedCartItems[0].qty -= 1
      }

    },
    enableAdd: (state, action: PayloadAction<string>) => {
      console.log({action})
      const {payload: productKey} = action
      if (state.productsData[productKey] === undefined) {
        state.productsData[productKey] = foodCartInitState;
      }
        state.productsData[productKey].isAdded = !state.productsData[productKey].isAdded
      state.productsData[productKey].qty += 1
      // state.value -= 1
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFood.pending, (state) => {
        state.status = "loading"
      })
      .addCase(fetchFood.fulfilled, (state, action) => {
        state.status = "idle"
        console.log("fetchFood.fulfilled action.payload:", action.payload)
        // state.value += action.payload
        state.productList = action.payload
        action.payload.forEach(product => {
          if (!state.productsData.hasOwnProperty(product.name)){
            state.productsData[product.name] = foodCartInitState
          }
        })
      })
      .addCase(fetchFood.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const {increment, decrement, enableAdd, incrementByAmount} = foodOrderingSlice.actions

export const selectCount = (state: RootState) => state.foodOrdering.value
export const productsDataState = (state: RootState) => state.foodOrdering.productsData
export const cartState = (state: RootState) => state.foodOrdering.cart
export const productsListState = (state: RootState) => state.foodOrdering.productList


export default foodOrderingSlice.reducer
