import React, {useEffect, useState} from "react"
import styles from "../FoodOrdering.module.css"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {cartState, decrement, enableAdd, increment, productsDataState} from "../foodOrderingSlice";
import {FoodProduct} from "../types";

interface ProductListItemProps {
  product: FoodProduct
}

// @ts-ignore
// const telegramWebApp = window.Telegram.WebApp

const ProductListItem: React.FC<ProductListItemProps> = ({product}) => {
  const dispatch = useAppDispatch()
  const productsData = useAppSelector(productsDataState)
  const cart = useAppSelector(cartState)
  const [qyt, setQyt] = useState(0);

  useEffect(() => {
  }, [])

  useEffect(() => {
    const matchedProductInCart = cart.filter(x => x.product.name === product.name)
    if (matchedProductInCart.length) {
      setQyt(matchedProductInCart[0].qty);
    }
  }, [cart])


  return (
    <div className={styles.productItem}>
      <div className={styles.productImageSection}>
        <div className={styles.productImageContainer}
        >
          <img src={"data:image/png;base64," + product.image128} className={styles.productImage} alt="product-image"/>
        </div>
      </div>
      <div className={styles.descriptionSection}>
        {product.name} {product.salePrice}$
      </div>
      {qyt > 0 ? (
        <div className={styles.buttonSection}>
          <button
            className={[
              styles.button,
              styles.addMinusButton,
              styles.minusButton
            ].join(" ")}
            aria-label="Decrement value"
            onClick={() => dispatch(decrement(product))}
          >
            -
          </button>

          <button
            className={[
              styles.button,
              styles.addMinusButton,
              styles.addButton
            ].join(" ")}
            aria-label="Increment value"
            onClick={() => dispatch(increment(product))}
          >
            +
          </button>
        </div>

      ) : (
        <div className={[styles.buttonSection].join(" ")}>
          <button
            className={[styles.button, styles.enableAddButton].join(" ")}
            aria-label="ADD"
            onClick={() => dispatch(increment(product))}
          >
            ADD
          </button>
        </div>
      )}
      <span className={styles.orderingQty}>{qyt}</span>
    </div>
  )
}

export default ProductListItem;
