import React, {useEffect, useState} from "react"

import {useAppDispatch, useAppSelector} from "../../app/hooks"
import {fetchFood, productsDataState, productsListState,} from "./foodOrderingSlice"
import styles from "./FoodOrdering.module.css"
import ProductListItem from "./components/ProductListItem";


export function FoodOrdering() {
  const productsData = useAppSelector(productsDataState)
  const productsList = useAppSelector(productsListState)
  const dispatch = useAppDispatch()
  const [incrementAmount, setIncrementAmount] = useState("2")

  const incrementValue = Number(incrementAmount) || 0

  useEffect(() => {
    dispatch(fetchFood());
  }, [productsList])


  return (
    <div className={styles.productList}>
      {productsList && productsList.map((product) => (
        <ProductListItem product={product} key={product.name}/>
      ))}

      {/*<div className={styles.testBox}>*/}
      {/*  {*/}
      {/*    // @ts-ignore*/}
      {/*    // Object.values(productsData).reduce((x, y) => {*/}
      {/*    //   return (x.qty + y.qty)*/}
      {/*    // }).toString()*/}
      {/*  }*/}
      {/*</div>*/}
    </div>
  )
}
