import logo from "./logo.svg"
import { Counter } from "./features/counter/Counter"
import "./App.css"
import {FoodOrdering} from "./features/foodOrdering/FoodOrdering";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <FoodOrdering />
        {/*<img src={logo} className="App-logo" alt="logo" />*/}
        {/*<Counter />*/}
        {/*<p>*/}
        {/*  Edit <code>src/App.tsx</code> and save to reload.*/}
        {/*</p>*/}

      </header>
    </div>
  )
}

export default App
