// A mock function to mimic making an async request for data
import {foodList} from "./constants/sampleFoodData";
import {FoodProduct} from "./types";

export function fetchCount(amount = 1) {
  return new Promise<{ data: number }>((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500),
  )
}

export function fetchFoodProducts() {
  return new Promise<{ data: FoodProduct[] }>((resolve) =>
    setTimeout(() => resolve({ data: foodList }), 0),
  )
}
